import React from "react"
import LeftCardProfile from "../../../components/layout/LeftCardProfile"
import { Col, Container } from "react-bootstrap"
import * as styles from "./styles.module.scss"

const Appointment = () => {
	return (
		<div className={styles.providerProfileMain}>
			<div>
				<Container>
					<div className="row pt-5">
						<Col md={4} className="pb-3">
							<LeftCardProfile />
						</Col>
						<Col md={8}>
							<div className={`${styles.add} ${styles.contactInfo}`}>
								<h3 className="mt-5">
									Upcoming Appointments <span className="text-secondary">0</span>
								</h3>
							</div>
							<div className={styles.rightCard1}>
								<div className={styles.account}></div>
							</div>
							<div className={`${styles.add} ${styles.contactInfo}`}>
								<h3 className="mt-5">
									Past Appointments <span className="text-secondary">0</span>
								</h3>
							</div>
							<div className={styles.rightCard1}>
								<div className={styles.account}></div>
							</div>
						</Col>
					</div>
				</Container>
			</div>
		</div>
	)
}

export default Appointment
