import { Main } from "../components/layout"
import React from "react"
import Appointment from "../components/pages/appointment"

const AppointmentsPage = () => {
	return (
		<Main>
			<Appointment />
		</Main>
	)
}

export default AppointmentsPage
